export const REPETIR_MENSAL = 1
export const REPETIR_BIMESTRAL = 2
export const REPETIR_TRIMESTRAL = 3
export const REPETIR_SEMESTRAL = 6
export const REPETIR_ANUAL = 12

export const REPETIR = {
    [REPETIR_MENSAL]: {title: 'Mensal'},
    [REPETIR_BIMESTRAL]: {title: 'Bimestral'},
    [REPETIR_TRIMESTRAL]: {title: 'Trimestral'},
    [REPETIR_SEMESTRAL]: {title: 'Semestral'},
    [REPETIR_ANUAL]: {title: 'Anual'},
}
