import http from '../../../../utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/planos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/planos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newPlano = (data) => {
  let url = `/api/planos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updatePlano = (id, data) => {
  let url = `/api/planos/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
